import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 300.000000 424.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,424.000000) scale(0.100000,-0.100000)">

<path d="M1065 2951 c-66 -17 -155 -84 -155 -117 0 -23 25 -16 56 14 41 40 72
57 128 71 58 14 141 1 179 -30 31 -24 57 -25 57 -1 0 30 -93 68 -170 69 -36 1
-78 -2 -95 -6z"/>
<path d="M1710 2926 c-66 -32 -75 -42 -61 -64 6 -11 16 -8 46 12 52 36 110 49
178 42 61 -6 92 -21 145 -70 32 -30 59 -27 47 6 -6 15 -90 83 -103 83 -4 0
-14 5 -22 10 -8 5 -49 10 -90 11 -67 2 -83 -2 -140 -30z"/>
<path d="M1058 2859 c-18 -10 -46 -30 -61 -47 -24 -25 -26 -31 -13 -41 12 -10
20 -7 42 18 48 54 137 67 202 28 34 -20 63 -12 47 12 -27 45 -155 62 -217 30z"/>
<path d="M1749 2853 c-47 -24 -61 -45 -39 -58 6 -3 24 4 42 16 20 15 47 23 77
24 58 2 89 -9 124 -41 26 -24 57 -27 57 -6 0 11 -51 52 -89 71 -43 23 -118 20
-172 -6z"/>
<path d="M1093 2779 c-43 -21 -60 -46 -44 -62 6 -6 21 0 41 17 36 30 66 33 98
10 23 -16 42 -12 42 11 0 11 -66 45 -88 45 -4 0 -26 -9 -49 -21z"/>
<path d="M1783 2778 c-22 -11 -38 -36 -28 -46 3 -3 20 0 38 6 49 17 78 15 104
-9 19 -18 25 -20 38 -9 12 10 13 16 3 27 -32 39 -108 54 -155 31z"/>
<path d="M1123 2703 c-15 -6 -18 -63 -3 -63 6 0 10 -79 10 -218 l0 -218 35 4
35 4 0 -46 c0 -46 0 -46 -35 -46 l-35 0 0 -142 0 -143 80 0 80 0 0 48 c0 46
-1 47 -30 47 -29 0 -30 1 -30 51 0 51 0 51 30 47 l30 -5 0 136 1 136 61 -65
c33 -36 80 -86 104 -112 l44 -47 48 51 c26 28 73 79 104 112 l58 61 0 -136 0
-136 30 5 c30 4 30 4 30 -47 0 -50 -1 -51 -30 -51 -29 0 -30 -1 -30 -47 l0
-48 79 0 80 0 0 143 0 142 -35 0 c-34 0 -34 0 -34 46 l0 47 33 -6 33 -5 -4
201 c-2 141 0 213 9 239 13 40 2 68 -27 68 -21 0 -46 -32 -40 -52 3 -14 -12
-38 -52 -82 -92 -100 -208 -229 -230 -256 -14 -16 -24 -22 -30 -15 -145 153
-312 352 -312 370 0 29 -26 41 -57 28z m140 -185 c46 -51 106 -118 133 -149
27 -31 62 -64 77 -73 26 -17 29 -17 54 -1 14 10 42 35 62 58 20 22 64 70 97
106 34 36 74 81 89 99 15 18 36 37 46 43 18 9 19 3 19 -166 0 -158 -2 -177
-19 -194 -10 -10 -24 -20 -30 -22 -6 -2 -12 -23 -13 -47 -1 -36 4 -48 31 -76
l32 -33 -3 -94 -3 -94 -47 -3 c-55 -4 -61 7 -23 42 36 33 35 96 -1 129 -25 23
-27 31 -29 127 -4 126 -15 144 -67 112 -18 -11 -60 -50 -92 -86 -33 -36 -66
-66 -74 -66 -8 0 -47 33 -86 74 -39 40 -82 79 -95 86 -39 20 -56 -7 -57 -90
-2 -122 -4 -128 -29 -156 -33 -35 -36 -108 -5 -124 11 -6 20 -20 20 -30 0 -17
-7 -20 -39 -20 -48 0 -51 7 -51 113 0 77 1 82 32 114 42 44 42 93 -2 136 l-30
30 1 173 c0 96 4 174 9 174 5 0 46 -42 93 -92z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
